// @ts-ignore
import { Json } from "@types/common";
import { SBA7A } from "@views/exchange/constants";

export const ROUTES = {
  DASHBOARD: "/dashboard",
  EXCHANGE: "/exchange",
  ADMIN: "/admin/*",
  SIGN_IN: "/sign-in",
  NO_ROUTE: "/",
  CHOOSE_METHOD: "/choose-method",
  LOGIN: "/login",
  SIGNINFORM: "/signinform",
  CHOOSEMETHOD: "/choose-method",
  OTPVERIFY: "/otpverify",
  MOBILELINKSENT: "/mobile-linksent",
  BIOMETRICAPPROVALSENT: "/biometric-approvalSent",
  NEWUSEROTPVERIFY: "/newUser-otpverify",
  QR_RESPONSE: "/qr-response",
  Otp_Verify: "/otp-verify",
  REPORTS: "/reports",
  PROFILE: "/profile/*",
  WALLET: "/wallet",
  API_DESIGNER: "/api-designer/*",
  AUCTION: "/auction",
  AUCTION_LIST: "/auction-list",
  EDIT_AUCTION: "/auction-edit/:id",
  AUCTION_DETAILS: "/auction-details/:id",
  USER_BID_LIST: "/user-bid-list",
  USER_BID_DETAILS: "/user-bid-details/:id",
  IPO: "/ipo/*",
  ASSETS: "/minted/assets",
  SBA: "/minted/sba7",
  NEWS: "/news",
  ORDERSPAGE: "/orders",
  ORDERDETAILS: "/orders/:id",
  // FULLNEWS: "/fullnews",
  PORTFOLIO: "/portfolio",
  PORTFOLIO_ROUTE: "/portfolio/*",
  WALLET_ROUTE: "/wallet/*",
  CREATE_AUCTION: "/auction-create",
  AUCTION_DRAFT: "/drafts",
  AUTHORISED_USERS: "/authorise-users",
  ACCOUNTS: "/accounts",
  CARDS: "/cards",
  TRANSACTIONS: "/transactions",
  TRANSACTION_DETAILS: "/transactions/:id",
  COOWNERS: "/co-owners-email-accept/:userId",
  COOWNERSREMOVE: "/co-owners-email-remove/:userId",
  COOWNERSINVITE: "/co-owner-invitation",
  COOWNERSREMOVALREQUEST: "/co-owner-removal-request",
  COOWNERSREMOVALREQUESTCANCEL: "/co-owner-request-cancel",
  LINKED_DEVICES: "/linked-devices",
  MOBILE_VERIFICATION: "/mobile-verification",
  EMAILVERIFY: "/email-verify",
  COMINGSOON: "/coming-soon",
  TERMS_SERVICE: "/terms-of-service",
  PRIVACY_POLICY: "/privacy-policy",
  BIOMETRIC_POLICY: "/biometric-policy",
  CONTACT_US: "/contact-us",
  ACCESS_DENIED: "/access-denied",
  MINTED_APP: "/minted/*",
  EMAILVERIFY_DENIED: "/emailverify-denied",
  CONTINUEINMOBILE: "/continue-in-mobile",
  MPC_SETUP: "/mpc-setup",
  MPC_QR: "/mpc-qr",
  MPC_SETUP_STATUS: "/mpc-setup-status",
  MPC_MOBILE_REDIRECT: "/mpc-mobile-redirect",
  SWITCH_ACCOUNT: "/switch-account",
};

export const ACTIVE_TABS = {
  // API_DESIGNER: "admin/api-designer",
  AUCTION: "admin/auction",
  AUCTION_LIST: "admin/auction-list",
  AUCTION_DRAFT: "admin/drafts",
  ASSETS: "admin/minted/assets",
  REALESTATE: "admin/minted/real-estate",
  SBA: "admin/minted/sba7",
  MHL: "admin/minted/manufactured-home-loans",
  EF: "admin/minted/equipment-finance",
  MUSIC: "admin/minted/music",
  REPORTS: "admin/reports",
  IPO: "admin/minted/ipo",
};

export const ADMIN_URLS_NAME: Json = {
  // "/admin/api-designer": "API Designer",
  "/admin/auction-create": "Auction Details",
  "/admin/auction": "Auction",
  "/admin/auction-list": "Auction",
  "/admin/drafts": "Drafts",
  "/admin/auction-details": "Auction Details",
  "/admin/auction-edit": "Auction Details",
  "/admin/minted/ipo": "IPO",
  "/admin/reports": "Reports",
  "/admin/minted/assets": "Minted Assets",
  "/admin/minted/real-estate": "Real Estate",
  "/admin/minted/sba7": SBA7A,
  "/admin/minted/create-sba7": SBA7A,
  "/admin/minted/edit-sba7": SBA7A,
  "/admin/minted/manufactured-home-loans": "Manufactured home loans",
  "/admin/minted/create-manufactured-home-loans": "Manufactured home loans",
  "/admin/minted/equipment-finance": "Equipment Finance",
  "/admin/minted/create-equipment-finance": "Equipment Finance",
};

export const API_DESIGNER_URLS: Json = {
  home: "/admin/api-designer",
  documentation: "/admin/api-designer/documentations",
};

export const SIDEBAR_TABS = {
  label: "Main Menu",
  tabs: [
    // {
    //   ROUTE: ACTIVE_TABS.API_DESIGNER,
    //   TITLE: "API Designer",
    //   ICON: "ri-flow-chart",
    //   KEY: "apiDesigner",
    // },
    // {
    //   ROUTE: ACTIVE_TABS.AUCTION,
    //   TITLE: "Auction",
    //   ICON: "ri-auction-line",
    //   KEY: "auction",
    //   SUB_ROUTE: [
    //     {
    //       ROUTE: ACTIVE_TABS.AUCTION,
    //       TITLE: "My Auctions",
    //       ICON: "ri-auction-line",
    //     },
    //     {
    //       ROUTE: ACTIVE_TABS.AUCTION_DRAFT,
    //       TITLE: "Drafts",
    //       ICON: "ri-draft-line",
    //     },
    //     {
    //       ROUTE: ACTIVE_TABS.AUCTION_LIST,
    //       TITLE: "Auction List",
    //       ICON: "ri-list-check-2",
    //     },
    //   ],
    // },
    {
      ROUTE: ACTIVE_TABS.ASSETS,
      TITLE: "Minted Assets",
      ICON: "ri-funds-line",
      KEY: "mintedAsset",
      SUB_ROUTE: [
        {
          ROUTE: ACTIVE_TABS.ASSETS,
          TITLE: "Private Stock",
          ICON: "ri-funds-line",
          ID: "MINTED_ASSETS_PRE_IPO",
        },
        {
          ROUTE: ACTIVE_TABS.REALESTATE,
          TITLE: "Real Estate",
          ICON: "ri-funds-line",
          ID: "MINTED_ASSETS_REAL_ESTATE",
        },
        {
          ROUTE: ACTIVE_TABS.SBA,
          TITLE: SBA7A,
          ICON: "ri-discount-percent-line",
          ID: "MINTED_ASSETS_SBA7",
        },
        {
          ROUTE: ACTIVE_TABS.MHL,
          TITLE: "Manufactured Home Loans",
          ICON: "ri-discount-percent-line",
          ID: "MINTED_ASSETS_MANU_HOME_LOANS",
        },
        {
          ROUTE: ACTIVE_TABS.EF,
          TITLE: "Equipment Finance",
          ICON: "ri-discount-percent-line",
          ID: "MINTED_ASSETS_EQUIPMENT_FINANCE",
        },
        // {
        //   ROUTE: ACTIVE_TABS.MUSIC,
        //   TITLE: "Music",
        //   ICON: "ri-music-2-line",
        //   ID: "MINTED_ASSETS_MUSIC",
        // },
      ],
    },
    // {
    //   ROUTE: ACTIVE_TABS.REPORTS,
    //   TITLE: "Reports & Analytics",
    //   ICON: "ri-funds-line",
    // },
    {
      ROUTE: ACTIVE_TABS.IPO,
      TITLE: "IPO",
      ICON: "ri-file-chart-line",
      KEY: "ipo",
      ID: "MINTED_IPO",
    },
  ],
};

export const CLIENT_ENVIRONMENT: any = {
  prod: "prod",
};
