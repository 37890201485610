import { FC, useEffect } from "react";
import useDarkMode from "use-dark-mode";

import { useCurrency } from "hooks";

const chartWidth = "100%";

interface Props {
  width?: number;
  chartName: string;
  isLoading: boolean;
  stacked?: boolean;
  columnColor: any[];
  data: any[];
  xAxis: any[];
  yMax?: number | null;
}

export const BarChart: FC<Props> = ({
  yMax,
  isLoading,
  data,
  columnColor,
  chartName,
  xAxis,
  stacked = false,
}) => {
  const { formatCurrencyWithBillion } = useCurrency();
  const darkMode = useDarkMode();

  useEffect(() => {
    const chart = (window as any).Highcharts?.chart(chartName, {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        height: 300,
        style: {
          fontFamily: "Poppins",
        },
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      },
      credits: {
        enabled: false,
      },

      colors: columnColor,

      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        categories: xAxis,
        labels: {
          style: {
            color: darkMode.value ? "#FFFFFF" : "#17191F",
          },
        },
      },
      yAxis: {
        max: yMax,
        min: 0,
        title: {
          text: null,
        },
        gridLineWidth: 0.6,
        stackLabels: {
          enabled: false,
        },
        labels: {
          formatter: function (this: any) {
            return formatCurrencyWithBillion(this.value, 1);
          },
          style: {
            color: darkMode.value ? "#FFFFFF" : "#17191F",
          },
        },
      },

      legend: {
        align: "right",
        verticalAlign: "top",
        x: 8,
        y: -5,
        borderColor: "#D7DFF5",
        borderWidth: 1,
        borderRadius: 8,
        itemStyle: { color: darkMode.value ? "#BAC4DB" : "#5D657A" },
        itemHoverStyle: {
          color: darkMode.value ? "#FFFFFF" : "#17191F",
        },
      },
      exporting: { enabled: false },

      tooltip: {
        backgroundColor: darkMode.value ? "#36383D" : "#F0F4FF",
        borderRadius: 8,
        style: {
          color: darkMode.value ? "#FFFFFF" : "#17191F",
          borderRadius: 8,
        },
        formatter: function (this: any) {
          return chartName === "profit"
            ? `<span>${this.x}</span></br><b><span style="color:${
                stacked ? this.series.color : "#3C65D6"
              }">` +
                this.series.name +
                " : </span>" +
                formatCurrencyWithBillion(this.y, 2) +
                "</b></br> <b>" +
                `<span style="color:${
                  stacked ? this.series.color : "#3C65D6"
                }">` +
                this.series.name +
                " Percentage : " +
                "</span></b>" +
                "<b>" +
                this?.point?.options?.percent +
                "%" +
                "</b>"
            : `<span>${this.x}</span></br><b><span style="color:${
                stacked ? this.series.color : "#3C65D6"
              }">` +
                this.series.name +
                " : </span>" +
                formatCurrencyWithBillion(this.y, 2) +
                "</b>";
        },
      },

      plotOptions: {
        column: {
          stacking: stacked ? "normal" : null,
          pointPadding: 0.2,
          borderWidth: 0,
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: false,
            },
          },
        },
        series: {
          borderRadiusTopLeft: 50,
          borderRadiusTopRight: 50,
          maxPointWidth: 24,
          minPointLength: !stacked && 10,
          groupPadding: 0.3,
          pointPadding: 0.2,
          events: {
            legendItemClick: function (this: any) {
              return this.chart.series.some((item: any) =>
                this === item ? !item.visible : item.visible
              );
            },
          },
        },
      },
      series: data,
    });

    if (isLoading) {
      chart?.showLoading();
    } else {
      chart?.hideLoading();
    }
  }, [
    chartName,
    columnColor,
    darkMode.value,
    data,
    formatCurrencyWithBillion,
    isLoading,
    stacked,
    xAxis,
    yMax,
  ]);

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          backgroundColor: "transparent",
          width: chartWidth,
          height: "100%",
        }}
      >
        <div id={chartName}></div>
      </div>
    </div>
  );
};
