import React, { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { Json } from ".././../../../types/common";
import { useCurrency } from "@hooks/currency";
import { capitalizeFirstLetterOnly } from "@utils/common";
import { Image } from "@storybook";
import { NotificationVisibleState } from "@views/Notification/store/state";
import UseNotificationApi from "@views/Notification/store/useNotificaitonApi";

import "./orderNotification.scss";
import { getCategoryLabel } from "@utils/getString";
import useDarkMode from "use-dark-mode";

const OrderNotification = (data: Json) => {
  const setVisibleNotification = useSetRecoilState(NotificationVisibleState);
  const navigate = useNavigate();
  const {
    metadata: { orderId = '', tradeId = '' },
  } = data?.data ?? {};
 
  const { updateMarkAsRead } = UseNotificationApi();
  const { formatCurrencyWithBillion } = useCurrency();
  const darkMode = useDarkMode();
  const notificationData = useMemo(() => {
    return {
      title: `Order ${
        data?.data?.type === "ORDER_CREATION"
          ? "placed"
          : data?.data?.type === "ORDER_CANCELLATION"
          ? "canceled"
          : data?.data?.type === "ORDER_MODIFICATION"
          ? "modified"
          : data?.data?.type === "ORDER_EXECUTION"
          ? "fulfilled successfully"
          : ""
      }`,
      time: data?.data?.createdAt
        ? `${new Date(data?.data?.createdAt).getDate()} ${new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-US", {
            month: "short",
          })}, ${new Date(data?.data?.createdAt).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`
        : "-",
      userName: data?.data?.metadata?.orderPlacedBy,
      readStatus: data?.data?.readStatus,
      type: data?.data?.type,
      qty: data?.data?.metadata?.quantity,
      symbol: data?.data?.metadata?.assetName,
      avgPrice: data?.data?.metadata?.avgPrice,
      notificationId: data?.data?._id,
      assetType: getCategoryLabel(data?.data?.metadata?.assetType),
      sbaType: data?.data?.metadata?.sbaType?.replace(/-/g, ' ') || "",
      orderType: data?.data?.metadata?.orderType,
      userAvatar: data?.data?.metadata?.profileUrl,
      bank: data?.data?.details?.bank,
      amount: data?.data?.metadata?.totalAmount,
    };
  }, [data?.data?._id, data?.data?.createdAt, data?.data?.details?.bank, data?.data?.metadata?.assetName, data?.data?.metadata?.assetType, data?.data?.metadata?.avgPrice, data?.data?.metadata?.orderPlacedBy, data?.data?.metadata?.orderType, data?.data?.metadata?.profileUrl, data?.data?.metadata?.quantity, data?.data?.metadata?.totalAmount, data?.data?.readStatus, data?.data?.type]);

  const handleNotificationClick = useCallback(
    (id: string) => {
      setVisibleNotification(false);
      if (notificationData?.readStatus === "UNREAD") {
        updateMarkAsRead({ id: id });
      }
      if (data?.data?.type === "ORDER_EXECUTION") {
        navigate(`/orders/${orderId}`, {
          state: {
            tradeId: tradeId || null,
          },
        });
      } else {
        navigate(`/orders/${orderId}`, { state: { type: data?.data?.type } });
      }
     
    },
    [data?.data?.type, navigate, notificationData?.readStatus, orderId, setVisibleNotification, tradeId, updateMarkAsRead]
  );

  return (
    <div
      className="notificationSection"
      onClick={() => handleNotificationClick(notificationData?.notificationId)}
    >
      <div className="orderNotification">
        <div className="orderNotification__left">
          <div className="orderNotification__left__icon">
            <i
              className={`${
                notificationData?.type === "ORDER_CANCELLATION"
                  ? "ri-close-circle-fill cancelledOrder"
                  : "ri-checkbox-circle-fill orderCreation "
              }`}
            />
          </div>
          {notificationData?.readStatus === "UNREAD" && (
            <div className="buysellNotification__left__readMark"></div>
          )}
          <div>
            <div className="orderNotification__left__title">
              {notificationData?.title}
            </div>
            <div className="orderNotification__left__Subtitle">
              {" "}
              {notificationData?.symbol}
            </div>
            <div className="orderNotification__left__userDetail">
            <Image className="bankNotification__left__userDetail__userImg"
                       fileName={darkMode.value ? "images/avtarDark.svg" : "images/avatarLight.svg"}
                       useFileNameOnFail {...!!notificationData?.userAvatar && { url: notificationData?.userAvatar }} />
              <div className="orderNotification__left__userDetail__userName">
                {notificationData?.userName}
              </div>
            </div>
          </div>
        </div>
        <div className="orderNotification__right">
          <div className="orderNotification__right__amount">
            {formatCurrencyWithBillion(notificationData?.amount, 8)}
          </div>
          <div className="orderNotification__right__orderDetails">
            <span className="orderNotification__right__orderDetails__qty">
              <div>QTY</div>
              <span>{notificationData?.qty}</span>
            </span>
            <span className="orderNotification__right__orderDetails__bullet"></span>
            <span className="orderNotification__right__orderDetails__price">
              <div>Avg. price</div>
              <span>
                {formatCurrencyWithBillion(notificationData?.avgPrice, 8)}
              </span>
            </span>
          </div>
          <div className="orderNotification__right__orderDetails__orderType">
            {!!notificationData?.sbaType && <div>{capitalizeFirstLetterOnly(notificationData?.sbaType) ?? "--"}</div>}
            <div>{notificationData?.assetType ?? "--"}</div>
            <span
              className={`${
                notificationData?.orderType === "BUY"
                  ? "orderNotification__right__orderDetails__orderType__BUY"
                  : "orderNotification__right__orderDetails__orderType__SELL"
              }`}
            >
              {capitalizeFirstLetterOnly(notificationData?.orderType)}
            </span>
          </div>
        </div>
      </div>
      <div className="orderNotification__right__time">
        {notificationData?.time}
      </div>
    </div>
  );
};

export default OrderNotification;
