import { FullStory } from '@fullstory/browser';

export const useFullStoryTrackEvent = () => {
  const trackEvent = (eventName: string, properties: Record<string, any>): void => {
    FullStory('trackEvent', {
      name: eventName,
      properties,
    });
  };
  

  const trackPageEvent = (properties: Record<string, any>): void => {
    FullStory('setProperties', {
      type: 'page',
      properties: properties
    });
  };
  const setUserIdentifire =( uid: string, properties: Record<string, any> ): void => {
    FullStory('setIdentity', {
      uid: uid,
      properties
    });
  }

  const getSessionUrl = async (): Promise<string | null> => {
    try {
      const sessionUrlAtTime = await FullStory('getSessionAsync', { format: 'url.now' });
      return sessionUrlAtTime || null;
    } catch (error) {
      console.error('Error fetching FullStory session URL:', error);
      return null;
    }
  };

  return { trackEvent, trackPageEvent, setUserIdentifire, getSessionUrl };
};
