import { IAssetsState } from "states/exchange/type";

import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { APIS } from "constant";
import { useNetwork } from "hooks";
import { WatchlistState, userPersonalDetails } from "states";
import { debounce, getKeyValueObjectFromArray } from "utils";
import { INIT_CONFIGURATION_SAVE, useIndexedDB } from "hooks/indexed-db";
import { assetTabs } from "@views/exchange/constants";

export const useWatchlist = () => {
  //globle sates
  const [watchlistData, setWatchlistData] = useRecoilState(WatchlistState);
  const user = useRecoilValue(userPersonalDetails);
  const { watchlistId } = user;
  //hooks
  const { patch: updateWatchlist } = useNetwork();
  const { get: getExplorerAssets } = useNetwork();
  const { update } = useIndexedDB(INIT_CONFIGURATION_SAVE);
  const { WATCHLIST } = assetTabs;

  const watchlistID = watchlistId ?? "";

  const updateWatchlistFromAssets = useCallback(async () => {
    const resp = await getExplorerAssets(`${APIS.Explorers}?include=watchlist`);
    const { data } = resp ?? {};
    if (data) {
      const key = Object.keys(data);
      const watchlist = data[key[0]];
      setWatchlistData(getKeyValueObjectFromArray("id", watchlist));
    }
  }, [getExplorerAssets, setWatchlistData]);

  const debounceSearch = useCallback(
    debounce(updateWatchlistFromAssets, 5000),
    []
  );

  const addToWatchlist = useCallback(
    (
      item: IAssetsState,
      assetType: string | undefined,
      setAdded: (value: boolean) => void,
      from: string | undefined
    ) => {
      const {
        id,
        symbol,
        type,
        name,
        marketPrice,
        change,
        changesPercentage,
        image,
        imageFrom,
        imageTo,
        _id,
        amount,
        issuerDetails,
      } = item ?? {};
      const watchList = Object.values(watchlistData || {})
      let index = (watchList?.length ?? 0) + 1;
      const payload = {
        assets: [
          {
            symbol: symbol ?? "",
            index,
            type: assetType ?? type,
          },
        ],
      };
      const data = {
        ...item,
        symbol: symbol ?? "NA",
        index,
        type: assetType ?? type,
        _id: _id ?? "",
        id: id ?? "",
        name: name ?? "NA",
        marketPrice: (assetType === "music" ? amount : marketPrice) ?? 0,
        change: change ?? 0,
        changesPercentage: changesPercentage ?? 0,
        image: image ?? "",
        perDay: [],
        watchlist: watchlistID,
        imageFrom: imageFrom ?? "",
        imageTo: imageTo ?? "",
        issuerDetails
      };

      setAdded(true);
      setWatchlistData((prev) => ({...prev, [data?.id]: { ...data }}));
      update({...watchlistData, [data?.id]: { ...data }}, WATCHLIST);
      updateWatchlist(`${APIS.AddToWatchlist}${watchlistID}`, payload).then(
        (res) => {
          if (res && res.assets) {
            const addedAsset = res.assets.find(
              (asset: { symbol: string; type: string }) => {
                return asset.type === "privates"
                  ? asset.symbol === symbol.split(".")[1]
                  : asset.symbol === symbol ?? "";
              }
            );
            if (addedAsset) {
              setWatchlistData((prev) => {
                const prevState = JSON.parse(JSON.stringify(Object.values(prev || {})));
                const finalAddedWatchIndex = prevState.findIndex(
                  (asset: { symbol: string; type: string }) => {
                    return asset.type === "privates"
                      ? asset.symbol === addedAsset?.symbol.split(".")[1]
                      : asset.symbol === addedAsset?.symbol ?? "";
                  }
                );
                prevState[finalAddedWatchIndex] = {
                  ...prevState[finalAddedWatchIndex],
                  _id: addedAsset?._id,
                }
                const data = getKeyValueObjectFromArray("id", prevState)
                update(data, WATCHLIST);
                return data;
              });
            }
          }
        }
      );
      if (from === "search") debounceSearch();
    },
    [
      debounceSearch,
      setWatchlistData,
      updateWatchlist,
      watchlistData,
      watchlistID,
    ]
  );

  const removeFromWatchlist = useCallback(
    (asset_id: string, setAdded: (value: boolean) => void, id: string) => {
      const tempData = {...watchlistData};
      const payload = {
        deleteAssets: [asset_id],
      };
      setAdded(false);
      updateWatchlist(`${APIS.AddToWatchlist}${watchlistID}`, payload);
      if(tempData[id]) delete tempData[id]
      setWatchlistData({...tempData});
      update({...tempData}, WATCHLIST);
    },
    [setWatchlistData, updateWatchlist, watchlistData, watchlistID]
  );

  return { addToWatchlist, removeFromWatchlist };
};
