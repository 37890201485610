import { useEffect, useState } from "react";

import { formatNumberWithCommas, useFormatNumber } from "utils";
import { ASSETS_CONFIG, colorList } from "./../../constants";
import { newWatchlistKey } from "../Main";
import styles from "../Currency/Currency.module.sass";
import { useCurrency } from "hooks/currency";
import { darkThemeState, ActiveCurrencyState } from "states";
import { useRecoilValue } from "recoil";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface IPrice {
  marketPrice: number;
  change: number;
  changesPercentage: number;
  symbol: string;
  type?: string;
  className?: string;
  currentLoanBalance?: number;
  showBondPointTooltip?: boolean;
}

export const Price = ({
  marketPrice: propPrice,
  change: propChange,
  changesPercentage,
  symbol: propSymbol,
  type,
  currentLoanBalance,
  showBondPointTooltip = false,
  className = ""
}: IPrice) => {
  const [marketPrice, setmarketPrice] = useState(propPrice);
  const [bgColor, setBgColor] = useState(colorList.flickerWhite);
  const [change, setChange] = useState(propChange);
  const [newchangesPercentage, setNewChangePercentage] =
    useState(changesPercentage);
  const themeDark = useRecoilValue(darkThemeState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const { formatCurrency, formatCurrencyNumber, formatCurrencyWithBillion } =
    useCurrency();
  const { sbaAndNormalPrice } = useAuctionHook();

  useEffect(() => {
    setBgColor(themeDark ? colorList.flickerWhite : colorList.flickerBlack);
  }, [themeDark]);

  useEffect(() => {
    setmarketPrice(propPrice);
    setChange(propChange);
    setNewChangePercentage(changesPercentage);
  }, [changesPercentage, propChange, propPrice]);

  const { percentFormat } = useFormatNumber();
  // Removed flcker style from current_price div (style={{ color: bgColor }})
  return (
    <>
      <div className={`${styles.right} ${className}`}>
        <div className={styles.current_price}>
          {type === newWatchlistKey.sba7 ? (
            <>
              {sbaAndNormalPrice(
                { product: "SBA-7", category: "Loans", currentLoanBalance },
                {
                  price: marketPrice,
                }
              )}
            </>
          ) : (
            <>{formatCurrency(marketPrice, ASSETS_CONFIG[type as string]?.decimal)} </>
          )}
          {!!showBondPointTooltip && (<span>
          <i className={`ri-information-line ${styles.informationTooltip}`} data-tooltip-id={`${propSymbol}_bps`} ></i>
              <ReactTooltip
              id={`${propSymbol}_bps`}
              place="bottom"
              content={"1 bond point is equal to 1% of the current loan balance or face value"}
              className={"theme-tooltip"}
            />
          </span>)}
        </div>
        <div
          className={`${styles.price_change} ${
            (change ?? 0) > 0
              ? styles.positive
              : (change ?? 0) < 0
                ? styles.negative
                : styles.neutral
          }`}
        >
          {type === newWatchlistKey.sba7 ? (
            <div className={styles.neutral}>
              {formatCurrencyWithBillion(marketPrice, 2, true, true)}
            </div>
          ) : (
            <>
              <span
                className={`${styles.price_change_value} ${
                  (change ?? 0) > 0
                    ? styles.positive
                    : (change ?? 0) < 0
                      ? styles.negative
                      : styles.neutral
                }`}
              >
                {formatNumberWithCommas(change ?? 0, ASSETS_CONFIG[type as string]?.decimal)}
              </span>
              <span
                className={`${styles.price_change_percent} ${
                  (change ?? 0) > 0
                    ? styles.positive
                    : (change ?? 0) < 0
                      ? styles.negative
                      : styles.neutral
                }`}
              >
                {`(${
                  (change ?? 0) > 0 ? "+" : (change ?? 0) < 0 ? "-" : ""
                }${percentFormat(newchangesPercentage)})`}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};
