import { atom } from "recoil";
import { IAccountInfo,ITransactionsType } from "./type";
export const obj = {};

export const AllTransactionsData = atom<ITransactionsType>({
  key: "all-transactions-data",
  default: {loading:false, data:[], offset: 0, limit: 20},
});

export const FortressAccountInfo = atom<IAccountInfo[]>({
  key: "fortress-account-info",
  default: [],
});

