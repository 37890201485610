import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { ActiveAssetTab } from "states";

import {
  Watchlist,
  Stocks,
  Crypto,
  Forex,
  MarketIndex,
  Private,
  Comodity,
  Music,
  Sba,
} from "../../components";
import { assetTabs } from "../../constants";

export const Assets = () => {
  const activeTab = useRecoilValue(ActiveAssetTab);

  const {
    STOCKS,
    // MUSIC,
    FOREX,
    CRYPTO,
    MARKETINDEXES,
    PRIVATES,
    COMMODITIES,
    SBA7,
    MHL,
    EF,
    REALESTATE
  } = assetTabs;

  const renderExplorer = useCallback(
    (asset: string) => {
      switch (asset) {
        // case MUSIC:
        //   return <Music />;
        case SBA7:
          return <Sba />;

        case MHL:
          return <Sba />;
        case EF:
          return <Sba />;
        // case STOCKS:
        //   return <Stocks />;
        case CRYPTO:
          return <Crypto />;
        // case FOREX:
        //   return <Forex />;
        // case MARKETINDEXES:
        //   return <MarketIndex />;
        case PRIVATES:
          return <Private />;
        case REALESTATE:
          return <Private />;
        // case COMMODITIES:
        //   return <Comodity />;
        default:
          return <Watchlist />;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <>{renderExplorer(activeTab?.key)}</>;
};
