import React, { ChangeEvent } from "react";
import Cleave from "cleave.js/react";
import { Json } from "../../types/common";

interface CommaSeparatedInputProps {
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>, rawValue?: string | number) => void;
  type?: string;
  name?: string;
  className?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
  min?: number;
  autoComplete?: "on" | "off";
  onWheel?: React.WheelEventHandler<HTMLElement>
  placeholder?: string
  required?:boolean
  autoFocus?:boolean
  pattern?: string
  decimalPlaces?: number;
  maxDigits?: number;
  onFocus?: ()=> void;
}

export const CommaSeparatedInput: React.FC<CommaSeparatedInputProps> = ({
  value,
  onChange,
  type,
  name,
  className,
  onKeyPress,
  disabled,
  style,
  maxLength,
  min,
  autoComplete,
  onWheel,
  placeholder,
  required,
  autoFocus,
  pattern,
  decimalPlaces = 2,
  maxDigits = 10,
  onFocus = ()=> {}

}) => {
  return (
    <Cleave
      value={value}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        numeralDecimalMark: ".", // Optional: for decimal point
        delimiter: ",", // Optional: specify the delimiter
        numeralDecimalScale: decimalPlaces, // Allow decimal places based on the passed prop
        numeralIntegerScale: maxDigits, // Limit the number of digits before the decimal
      }}
      onChange={(e) => {
        const formattedValue = e.target.value; // Value with commas
        const rawValue = e.target.rawValue; // Numeric value without commas

        // Pass the raw numeric value to the parent component
        if (onChange) {
          onChange(e, rawValue);
        }
      }}
      onKeyPress={onKeyPress}
      disabled={disabled}
      maxLength={maxLength}
      className={className}
      style={style}
      min= {min}
      name={name}
      autoComplete={autoComplete}
      onWheel={onWheel}
      placeholder={placeholder}
      required={required}
      autoFocus={autoFocus}
      pattern={pattern}
      onFocus={onFocus}
    />
  );
};
