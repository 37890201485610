import styles from "./compliance.module.sass";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  darkThemeState,
  userPersonalDetails,
  openComplianceState,
  TriggerUserApi,
  reOpenKycSignupModalState, reinitiateKycState, isStartVerificationNewState,
  OpenKycReviewModalState
} from "states";
import { useNetwork } from "@hooks/network";
import { APIS } from "../../../../constant";
import { useNotification } from "@hooks/notification";
// @ts-ignore
import { Json } from "@types/common";
import { SessionDetailsModal } from "@components/SessionDetailsModal";
import { Loader } from "@components/Loader2";

interface IkycReview {
  onLogout?: () => void;
  setReinitiateKyc?: (value: boolean) => void;
  handleClose?: () => void
}

export const KycReview = ({ onLogout, handleClose }: IkycReview,) => {
  const personalData = useRecoilValue(userPersonalDetails);
  const setOpenCompliance = useSetRecoilState(openComplianceState);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);
  const { get: fetchSimpliciToken, loading: simpliciLoading } = useNetwork();
  const { errorNotification } = useNotification();
  const [simpliciToken, setSimpliciToken] = useState<string | null>(null);
  const setReinitiateKyc = useSetRecoilState(reinitiateKycState);
  const setIsStartVerification = useSetRecoilState(isStartVerificationNewState);
  const  setOpenKycReviewModal= useSetRecoilState(OpenKycReviewModalState);


  const {
    kycStatus,
    kybStatus,
    isOnboardingComplete,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
    status,
    kybDocStatus,
  } = personalData.onboardingData;

  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "pending");

  const isAnyStatusProcessing = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "processing");

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "rejected");

  const isAnyCompleted = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "completed");

  const noOneIsPendingAndRejected = !isAnyStatusPending && !isAnyRejected;

  const isAllInPending = isAnyStatusPending && !isAnyStatusProcessing && !isAnyRejected && !isAnyCompleted


  const handleCloseModal = useCallback(() => {
    setTriggerUserApi((prev) => !prev);
    //  setIsSuccessModal(false);
    setOpenCompliance(false);
    setOpenKycReviewModal(false)
    setReOpenKycSignupModal(false);
    if (handleClose) {
      handleClose();
    }
  }, []);

  const handleViewReport = useCallback(async () => {
    let response;
    try {
      response = await fetchSimpliciToken(APIS.SIMPLICI_TOKEN);
    } catch (e: any) {
      errorNotification(e?.message || "Failed to generate user token!");
    } finally {
      if (response?.message === "ok") {
        setSimpliciToken(response?.data);
      }
      if (response?.message !== "ok") {
        errorNotification(response?.message || "Failed to generate user token!");
      }
    }
  }, []);

  const handleOnCompleteKyc = useCallback(() => {
    if (noOneIsPendingAndRejected) {
      handleCloseModal()
      return
    }

    !!handleClose && handleClose();
    setReinitiateKyc(true);
    setOpenKycReviewModal(false)
    setIsStartVerification(true);
    setOpenCompliance(true);
    setReOpenKycSignupModal(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOneIsPendingAndRejected]);

// TODE : We maynneed this later if not need i will remove it
//   const renderKycStatus = useMemo(() => {
//     return   !isOnboardingComplete ? (
//       isAnyRejected ?(
//         rejectedCount >= 2 ? (
//         <div className={styles.kyc}>
//           {themeDark ? (
//             <Image fileName={"images/kyc-kyb-rejected.svg"} height={"220px"} />
//           ) : (
//             <Image
//               fileName={"images/kyc-kyb-rejected-light.svg"}
//               height={"220px"}
//             />
//           )}
//           <h2 className={styles.kyc_heading}>
//             Identity and business verification rejected
//           </h2>
//           <p className={styles.kyc_para}>
//             We regret to inform you that your identity and business verification
//             has been rejected.
//             {/* Please <a href= "">view the report</a> for details. */}
//           </p>
//           {renderUserDetail}
//           {renderBothButton("")}
//         </div>
//       ) : kybStatus === "rejected"  ? (
//         <div className={styles.kyc}>
//           {themeDark ? (
//             <Image fileName={"images/kyb-rejected.svg"} height={"220px"} />
//           ) : (
//             <Image
//               fileName={"images/kyb-rejected-light.svg"}
//               height={"220px"}
//             />
//           )}
//           <h2 className={styles.kyc_heading}>Business verification rejected</h2>
//           <p className={styles.kyc_para}>
//             We regret to inform you that your business verification has been
//             rejected. Your case has been flagged for a manual review, which our
//             team will attend to within 24-48 hours. Any updates will be
//             communicated to you.
//             {/* Please <a href= "">view the report</a> for details. */}
//           </p>
//           {renderUserDetail}
//           {renderBothButton("KYB")}
//         </div>
//       ) : (
//         <div className={styles.kyc}>
//           {themeDark ? (
//             <Image fileName={"images/kyc-rejected.svg"} height={"220px"} />
//           ) : (
//             <Image
//               fileName={"images/kyc-rejected-light.svg"}
//               height={"220px"}
//             />
//           )}
//           <h2 className={styles.kyc_heading}>Identity verification rejected</h2>
//           <p className={styles.kyc_para}>
//             We regret to inform you that your identity verification has been
//             rejected. Your case has been flagged for a manual review, which our
//             team will attend to within 24-48 hours. Any updates will be
//             communicated to you.
//             {/* Please <a href= "">view the report</a> for details. */}
//           </p>
//           {renderUserDetail}
//           {renderBothButton("KYC")}
//         </div>
//       )
//       ) :(

//         isAnyStatusPending && (
//       <div className={styles.kyc}>
//         {themeDark ? (
//           <Image fileName={"images/kyc-review.svg"} height={"220px"} />
//         ) : (
//           <Image fileName={"images/kyc-review-light.svg"} height={"220px"} />
//         )}
//         <h2 className={styles.kyc_heading}>Verification Under Review</h2>
//         <p className={styles.kyc_para}>
//           Your account verifications is currently under review. This process may
//           take some time. We will notify you once the verification is complete.
//           Thank you for your patience.
//         </p>
//         {/* {renderLogoutButton} */}
//         {renderBothButton("")}
//       </div>
//     )
//       ) 

//     ) : (
//       <div className={styles.kyc}>
//         {themeDark ? (
//           <Image fileName={"images/kyc-success.svg"} height={"220px"} />
//         ) : (
//           <Image fileName={"images/kyc-success-light.svg"} height={"220px"} />
//         )}
//         <h2 className={styles.kyc_heading}>Verification done successfully</h2>
//         <p className={styles.kyc_para}>
//           Your verification has been done successfully, now you can <br /> trade
//           and access your wallet
//         </p>
//         <button
//           className={`${styles.proceed_btn}`}
//           onClick={() => setOpenCompliance(false)}
//         >
//           Start trading
//         </button>
//       </div>
//     );
//   }, [isOnboardingComplete, isAnyRejected, rejectedCount, themeDark, renderUserDetail, renderBothButton, kybStatus, isAnyStatusPending, setOpenCompliance]);


  const kycRow = useCallback((type: string, status: string) => {
    return (
      <>
        {" "}
        {status && (
          <div className={styles.kycRow}>
            <div
              className={`${styles.kyc_dot} ${
                status === "completed" && styles.complete} ${status === "rejected" && styles.reject} ${status === "pending" && styles.pending}`}
            ></div>
            <div className={styles.kycRow_head}>
              {type}
              <div className={styles.kycRow_status}>
                {status === "pending" ? "Incomplete" : status === "processing"
                  ? "Under review"
                  : status}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }, [])

  const renderKycStatus = useMemo(() => {
    return (
      <div className={styles.kyc_review}>
        <h2 className={styles.kyc_heading_review}>Verification Status</h2>
        <p className={styles.kyc_para_review}>
          We'll review your information. Once your account get verified you can start using the platform.
        </p>

        {(isAnyRejected && !isAnyStatusPending) && <p className={styles.kyc_para_rejected}>
          <i className={`ri-alert-fill ${styles.kyc_para_rejected_icon}`} />
          Your verification was not approved. Reinitiate the process by
          selecting "Reinitiate Verification" below.
        </p>}

        <div className={styles.kyc__review}>
          <div className={styles.kyc_review_list}>
            {kycRow("KYC", kycStatus)}
            {kycRow("AML", amlStatus)}
            {kycRow("KYB", kybStatus)}
            {kycRow("KYB Doc", kybDocStatus)}
            {kycRow("Accreditation", accreditationStatus)}
            {kycRow("Sign Agreement", signDocStatus)}

            <div className={`${styles.btn_group} ${noOneIsPendingAndRejected && styles.btn_group_reverse}`}>
              {/* TODE : We maynneed this later if not need i will remove it
              {(isAnyStatusProcessing || isAnyRejected || isAnyCompleted) && <button
                className={`${styles.proceed_btn_review}`}
                onClick={handleViewReport}>
                {simpliciLoading ? <Loader dimension={20} /> : "View Report"}
              </button>} */}
              <button
                className={`${styles.proceed_btn_review_1}`}
                onClick={handleOnCompleteKyc}
              >
                {noOneIsPendingAndRejected ? "Explore Liquidity" : personalData?.sessionId && !isAnyStatusPending ? "Reinitiate Verification" : "Start Verification"}
              </button>
            </div>
            {!noOneIsPendingAndRejected && <button
              className={`${styles.proceed_btn_review_2}`}
              onClick={handleCloseModal}
            >
              Explore Liquidity
            </button>}
          </div>
          <div className={styles.kyc_review_progress}>
            <h3 className={styles.heading}>Verification Timeline</h3>
            <div className={styles.progressContainer}>
              <div className={`${styles.progress_bar} ${
                isAllInPending ? styles.notDone : isAnyStatusPending ? styles.inProgress : styles.complte
              }`}>
                <div>
                  <div className={`${styles.kyc_dot}`}></div>
                  <div className={`${styles.progressline}`}></div>
                </div>
                <div>
                  {" "}
                  <h3>Completed verification</h3>
                  <p>{isAllInPending ? "--" : "Submitted for review"}</p>
                </div>
              </div>
              <div
                className={`${styles.progress_bar} ${
                  isAnyStatusPending ? styles.notDone : (isAnyStatusProcessing && !isAnyStatusPending && !isAnyRejected) ? styles.inProgress : styles.complte
                }`}
              >
                <div>
                  <div className={`${styles.kyc_dot}`}></div>
                  <div className={styles.progressline}></div>
                </div>
                <div>
                  {" "}
                  <h3>In review</h3>
                  <p>Approx. 0-4 business days</p>
                </div>
              </div>
              <div
                className={`${styles.progress_bar} ${
                  isOnboardingComplete ? styles.complte : isAnyRejected && !isAnyStatusPending ? styles.reject : styles.notDone
                }`}
              >
                <div>
                  <div className={styles.kyc_dot}></div>
                </div>
                <div>
                  {" "}
                  <h3>{isAnyRejected && !isAnyStatusPending ? "Account rejected" : "Account ready"}</h3>
                  <p>
                    {isAnyRejected && !isAnyStatusPending ? "Verification was unsuccessful. Reinitiate the verification process to access trading and other features." : "You can trade, deposit, withdraw and use all other features."}
                  </p>
                </div>
              </div>
            </div>
            <h3>Have any questions?</h3>
            <p>Our support team can help you.</p>
            <div className={styles.mail}>
              <i className="ri-mail-line"></i>
              <a  href="https://mail.google.com/mail/?view=cm&fs=1&to=support@liquidity.io" target="_blank" rel="noopener noreferrer">support@liquidity.io</a>
            </div>
          </div>
        </div>
      </div>
    );
  }, [accreditationStatus, amlStatus, handleCloseModal, isOnboardingComplete, kybStatus, kycRow, kycStatus, simpliciLoading]);

  return <>
    {renderKycStatus}

    {!!simpliciToken &&
      <SessionDetailsModal handleClose={() => setSimpliciToken(null)}
                           token={simpliciToken} />
    }

  </>;
};
