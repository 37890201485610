import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import numeral from "numeral";
import { format } from "date-fns";

import { useCurrency } from "hooks";
import { ActiveCurrencyState } from "states";

import "./area-chart.scss";

interface Props {
  /**
   * Height of chart -- default: 220.
   */
  height?: number;
  width?: number;
  chartPerDay: IPerDay[];
  activeTab: any;
}

interface IPerDay {
  open: number;
  low: number;
  high: number;
  value: number;
  time: any;
  volume: number;
}

export const AreaChart: FC<Props> = ({ activeTab, chartPerDay }) => {
  //const animation = useRef(true)
  const [animation, setAnimation] = useState(true);
  //globle states
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  let chartRef = useRef<any>(null);

  // hooks
  const { formatCurrencyNumber } = useCurrency();

  const { symbol, amount } = activeCurrency;
  const bgColor = "#464C5C";

  const formatNumber = (value: number | string, decimal = 2) => {
    if (decimal === 2) {
      return numeral(value ?? 0).format("0,0.00");
    }
    return numeral(value ?? 0).format("0,0.0000");
  };

  const { labels, values, volumes, dateTimes, intervalOpenPrice, formattedNumbers, formattedVols, startingPoint } = useMemo(() => {
    const tempChartsPerData = [...chartPerDay]
    let labels: string[] = [];
    const values: number[] = [];
    let volumes: number[] = [];
    const dateTimes: any[] = [];
    const intervalOpenPrice = ((tempChartsPerData?.[0] as any)?.open ?? 0) ?? values[0];

    (tempChartsPerData ?? [])?.forEach(({ value, time, volume }: any) => {
      let formatStr = "MMM DD";
      switch (activeTab) {
        case "1M": {
          formatStr = "dd MMM";
          break;
        }
        case "1D": {
          formatStr = "HH:mm";
          break;
        }
        case "1W": {
          formatStr = "dd MMM";
          break;
        }
        case "1Y": {
          formatStr = "MMM yy";
          break;
        }
        case "2Y": {
          formatStr = "MMM yy";
          break;
        }
        case "ALL": {
          formatStr = "MMM yy";
          break;
        }
      }

      const formattedTime = time ?? format(time, formatStr);
      const parsedTime = parseInt(formattedTime, 10);

      // Pushing the formatted time into labels array
      labels.push(format(parsedTime, formatStr));

      // Pushing the formatted time and date into dateTimes array
      dateTimes.push({
        time: format(time, "HH:mm"),
        date: format(time, "MM/dd/yyyy"),
      });

      const newValue = formatCurrencyNumber(value, 2).split(",").join("");
      values.push(Number(newValue));
      !!volume && volumes.push(volume);
    });

    const formattedNumbers = values.map((num) => formatNumber(num, 2));
    const formattedVols = volumes.map((vol) => formatNumber(vol));
    volumes = volumes.map((vol) => trimVolume(vol));
    const startingPoint = (values[0] || 0);

    return { labels, values, volumes, dateTimes, intervalOpenPrice, formattedNumbers, formattedVols, startingPoint }
  }, [activeTab, chartPerDay, formatCurrencyNumber]);

  function trimVolume(num: number) {
    let stringNum = num.toString();
    let preVal = stringNum.slice(0, 2);
    let postVal = stringNum.slice(2, stringNum.length);
    return parseFloat(preVal.concat(".", postVal));
  }

  const getConfigDataForCart = useCallback(() => {
    const numbers = values;
    const volume = volumes;

    // setup
    const data = {
      labels,
      datasets: [
        {
          label: symbol,
          data: numbers,

          fill: {
            target: {
              value: intervalOpenPrice,
            },
            below: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea, data, scales } = chart;
              if (!chartArea) {
                return null;
              }
              return belowGradient(ctx, chartArea, data, scales);
            },
            above: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea, data, scales } = chart;
              if (!chartArea) {
                return null;
              }
              return aboveGradient(ctx, chartArea, data, scales);
            },
          },
          borderColor: (context: any) => {
            const chart = context.chart;
            const { ctx, chartArea, data, scales } = chart;
            if (!chartArea) {
              return null;
            }
            return getGradient(ctx, chartArea, data, scales);
          },
          tension: 0,
          pointRadius: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 2,
        },
        {
          label: "Stock Volume",
          type: "bar",
          data: volume,
          backgroundColor: "#51545C",
          pointHitRadius: 0,
          pointHoverRadius: 0,
          yAxisID: "volume",
        },
      ],
    };

    // dottedLine plugin block
    const dottedLine = {
      id: "dottedLine",
      beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
        const {
          ctx,
          data,
          chartArea: { left, right },
          scales: { y },
        } = chart;

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.setLineDash([1, 5]);
        ctx.strokeStyle = bgColor;
        ctx.moveTo(left, y.getPixelForValue(startingPoint));
        ctx.lineTo(right, y.getPixelForValue(startingPoint));
        ctx.stroke();
        ctx.closePath();
        ctx.setLineDash([]);

        ctx.beginPath();
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, y.getPixelForValue(startingPoint) - 10, left, 20);
        ctx.closePath();

        ctx.font = "12px sans-serif";
        ctx.fillStyle = "white";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(
          startingPoint.toFixed(2),
          left / 2,
          y.getPixelForValue(startingPoint)
        );
      },
    };

    // customTooltip plugin block
    const customTooltip = {
      id: "customTooltip",
      afterDraw(chart: any, args: any, pluginOptions: any) {
        const {
          ctx,
          chartArea: { top, bottom, left, right },
          scales: { x },
        } = chart;
        ctx.save();

        chart.canvas.addEventListener("mousemove", (e: any) => {
          tooltipPosition(e);
        });

        function tooltipPosition(mousemove: any) {
          const dateCursor = x.getValueForPixel(mousemove.offsetX);
          const dateIndex = dateCursor;
          const { date, time } = dateTimes[dateCursor] || {};

          if (!date) return;
          
          let xTooltip;
          let yTooltip;
          const rightSide = right - mousemove.offsetX;

          if (rightSide <= 170) {
            xTooltip = mousemove.offsetX - 170;
          } else {
            xTooltip = mousemove.offsetX + 20;
          }

          if (mousemove.offsetY <= 100) {
            yTooltip = mousemove.offsetY + 30;
          } else {
            yTooltip = mousemove.offsetY - 80;
          }

          let xleft = left;
          let xright = right;

          if (
            mousemove.offsetX >= xleft &&
            mousemove.offsetX <= xright &&
            mousemove.offsetY >= top &&
            mousemove.offsetY <= bottom
          ) {
            ctx.beginPath();
            ctx.fillStyle = bgColor;
            ctx.strokeStyle = bgColor;
            ctx.lineJoin = "round";
            ctx.lineWidth = 9;
            ctx.fillRect(xTooltip, yTooltip, 150, 60);
            ctx.strokeRect(xTooltip, yTooltip, 150, 60);
            ctx.closePath();
            ctx.restore();
            
            // text date
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.font = "bolder 12px sans-serif";
            ctx.fillText(date, xTooltip + 5, yTooltip + 10);
            ctx.restore();

            // text time
            ctx.textAlign = "right";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "lightgrey";
            ctx.font = "bolder 10px sans-serif";
            ctx.fillText(time, xTooltip + 150 - 5, yTooltip + 10);
            ctx.restore();

            // Line 2 Color DOT
            let dotColor;
            if (numbers[dateIndex] >= intervalOpenPrice) {
              dotColor = "rgba(75, 192, 192, 1)";
            } else {
              dotColor = "rgba(255, 26, 104, 1)";
            }

            const dotSpace = 15;
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = dotColor;
            ctx.font = "bolder 12px FontAwesome";
            ctx.fillText("\uf111", xTooltip + 5, yTooltip + 30);
            ctx.restore();

            // Line 2 Text Price
            const priceText = "Price: ";
            const priceTextWidth = ctx.measureText(priceText).width;
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "lightgrey";
            ctx.font = "12px sans-serif";
            ctx.fillText(priceText, xTooltip + 5 + dotSpace, yTooltip + 30);
            ctx.restore();

            const price = formattedNumbers[dateIndex];
            // Line 2 Price value
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.font = "bolder 12px sans-serif";
            ctx.fillText(
              "$" + price,
              xTooltip + 5 + dotSpace + priceTextWidth,
              yTooltip + 30
            );
            ctx.restore();

            // Line 3 Icon
            const iconSpace = 15;
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.font = "bolder 12px FontAwesome";
            ctx.fillText("\uf080", xTooltip + 5, yTooltip + 50);
            ctx.restore();

            // Line 3 Text Value
            const valueText = "Volume: ";
            const valueTextWidth = ctx.measureText(valueText).width;
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "lightgrey";
            ctx.font = "12px sans-serif";
            ctx.fillText(valueText, xTooltip + 5 + iconSpace, yTooltip + 50);
            ctx.restore();

            const vol = formattedVols[dateIndex] || 0;

            // Line 3 Value value
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.font = "bolder 12px sans-serif";
            ctx.fillText(
              vol,
              xTooltip + 5 + iconSpace + valueTextWidth,
              yTooltip + 50
            );
            ctx.restore();
          }
        }
      },
    };

    function getGradient(ctx: any, chartArea: any, data: any, scales: any) {
      const { bottom } = chartArea;
      const { y } = scales;
      const gradientBorder = ctx.createLinearGradient(0, 0, 0, bottom);
      let shift = y.getPixelForValue(startingPoint) / bottom;

      if (shift > 1) {
        shift = 1;
      }

      if (shift < 0) {
        shift = 0;
      }

      gradientBorder.addColorStop(0, "hsla(156, 72%, 42%, 1)"); // profit border
      gradientBorder.addColorStop(shift, "hsla(156, 72%, 42%, 1)");
      gradientBorder.addColorStop(shift, "hsla(0, 90%, 61%, 1)"); // loss border
      gradientBorder.addColorStop(1, "hsla(0, 90%, 61%, 1)");
      return gradientBorder;
    }

    function belowGradient(ctx: any, chartArea: any, data: any, scales: any) {
      const { bottom } = chartArea;
      const { y } = scales;
      const gradientBackground = ctx.createLinearGradient(
        0,
        y.getPixelForValue(startingPoint),
        0,
        bottom
      );
      gradientBackground.addColorStop(0, "hsla(0, 90%, 61%, 0)"); // this is for bellow only
      gradientBackground.addColorStop(1, "hsla(0, 90%, 61%, 0.4)");
      return gradientBackground;
    }

    function aboveGradient(ctx: any, chartArea: any, data: any, scales: any) {
      const { top } = chartArea;
      const { y } = scales;
      const gradientBackground = ctx.createLinearGradient(
        0,
        y.getPixelForValue(startingPoint),
        0,
        top
      );
      gradientBackground.addColorStop(0, "hsla(156, 72%, 42%, 0)"); // for above only
      gradientBackground.addColorStop(1, "hsla(156, 72%, 42%, 0.4)");
      return gradientBackground;
    }

    // config
    return {
      type: "line",
      data,
      options: {
        animation: animation,
        layout: {
          padding: {
            left: 10,
            right: 5,
          },
        },
        scales: {
          x: {
            ticks: {
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
            },
            grid: {
              drawOnChartArea: false,
              drawTicks: true,
              drawBorder: false,
              offset: false,
            },
          },
          y: {
            beginAtZero: true,
          },
          volume: {
            type: "linear",
            position: "right",
            min: 0,
            max: 1000,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
      },
      plugins: [dottedLine, customTooltip],
    };

  }, [dateTimes, formattedNumbers, formattedVols, intervalOpenPrice, animation, labels, symbol, values, volumes])

  function crosshairLine(chart: any, mousemove: any) {
    const {
      canvas,
      ctx,
      chartArea: { left, right, top, bottom },
    } = chart;

    const coorX = mousemove.offsetX;
    const coorY = mousemove.offsetY;

    chart.update("none");
    ctx.restore();

    if (coorX >= left && coorX <= right && coorY >= top && coorY <= bottom) {
      canvas.style.cursor = "crosshair";
    } else {
      canvas.style.cursor = "default";
    }

    ctx.strokeStyle = "#666";
    ctx.lineWidth = 1;
    ctx.setLineDash([3, 3]);

    if (coorX >= left && coorX <= right && coorY >= top && coorY <= bottom) {
      // Horizontal Line
      ctx.beginPath();
      ctx.moveTo(left, coorY);
      ctx.lineTo(right, coorY);
      ctx.stroke();
      ctx.closePath();

      // Vertical Line
      ctx.beginPath();
      ctx.moveTo(coorX, top);
      ctx.lineTo(coorX, bottom);
      ctx.stroke();
      ctx.closePath();
      crosshairLabel(chart, mousemove);
      crosshairPoint(chart, mousemove);
    }
    ctx.setLineDash([]);
  }

  function crosshairLabel(chart: any, mousemove: any) {
    const {
      ctx,

      chartArea: { left },
      scales: { y },
    } = chart;

    const coorY = mousemove.offsetY;

    ctx.font = "12px sans-serif";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    // yLabel
    ctx.beginPath();
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, coorY - 10, left, 20);
    ctx.closePath();

    ctx.fillStyle = "white";
    ctx.fillText(y.getValueForPixel(coorY).toFixed(2), left / 2, coorY);
  }

  function crosshairPoint(chart: any, mousemove: any) {
    const {
      ctx,
      data,
      chartArea: { left, right, width },
      scales: { x, y },
    } = chart;

    const coorX = mousemove.offsetX;

    ctx.beginPath();
    ctx.strokeStyle = "#FFF";
    ctx.lineWidth = 3;
    ctx.setLineDash([]);

    const angle = Math.PI / 180;

    const leftOffset = x.getPixelForValue(x.min) - left;
    const rightOffset = right - x.getPixelForValue(x.max);

    const width2 = width - (leftOffset + rightOffset);

    const segments = width2 / (chartPerDay.indexOf(x.max) - chartPerDay.indexOf(x.min));

    const yOpening = y.getPixelForValue(startingPoint); // solid
    let index =
      Math.floor((coorX - (left + leftOffset)) / segments) +
      chartPerDay.indexOf(x.min);

    let yStart = y.getPixelForValue(data.datasets[0].data[index]);
    let yEnd = y.getPixelForValue(data.datasets[0].data[index + 1]);

    let yInterpolation =
      yStart +
      ((yEnd - yStart) / segments) *
      (coorX - x.getPixelForValue(data.labels[index]));

    if (yOpening >= yInterpolation) {
      ctx.fillStyle = "rgba(75, 192, 192, 1)";
    } else {
      ctx.fillStyle = "rgba(255, 26, 104, 1)";
    }

    // draw the circle
    ctx.arc(coorX, yInterpolation, 5, angle * 0, angle * 360, false);
    ctx.fill();
    ctx.stroke();
  }

  useEffect(() => {
    const Chart = (window as any).Chart;
    Chart.defaults.font.family =
      "'FontAwesome', 'Helvetica', 'Helvetica Neue', 'Arial', sans-serif";

    const config = getConfigDataForCart();
    // render init block
    chartRef.current = new Chart(document.getElementById("myChart"), config);
    chartRef.current?.canvas?.addEventListener("mousemove", (e: any) => {
      crosshairLine(chartRef.current, e);
    });

    if (animation) {
      setAnimation(false);
    }

    return () => {
      chartRef.current?.destroy();
    }
  }, []);

  useEffect(() => {
    chartRef.current.config._config = getConfigDataForCart();
    chartRef.current.update();
  }, [chartPerDay]);

  return (
    <div className="chart_container" style={{ flex: 1 }}>
      <div className="chartCard chart_inner">
        <canvas
          style={{
            width: "100%",
            height: "calc( 100vh - 552px )",
          }}
          id="myChart"
          className="canvas-areaChart"
        />
      </div>
    </div>
  );
};
