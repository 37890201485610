// export const navItems = ["Open Orders", "Executed Orders", "Cancelled Orders"];
export const navItems = [
  { title: "Open Orders", type: "PENDING" },
  { title: "Executed Orders", type: "EXECUTED" },
  { title: "Canceled Orders", type: "CANCELLED" },
];

export const ORDER_TYPE: any = {
  PENDING: "PENDING",
  EXECUTED: "EXECUTED",
  CANCELLED: "CANCELLED",
};


export const tableHeaders = [
  "Symbol",
  "Order type",
  "Time",
  "Price (USD)",
  "Qty.",
  "Total",
  "Actions",
];
export const CancelledTableHeaders = [
  "Symbol",
  "Order type",
  "Asset type",
  "Qty.",
  "Price (USD)",
  "Time",
  "Total (USD)",
];

export const emptyTable = {
  NO_OPEN_ORDER: "No Open Orders",
  NO_EXECUTED_ORDER: "No Executed Orders",
  NO_CANCELLED_ORDER: "No Canceled Orders",
};
