import React, { useCallback, useMemo } from "react";
import { Json } from ".././../../../types/common";
import { useCurrency } from "@hooks/currency";
import { capitalizeFirstLetterOnly } from "@utils/common";
import UseNotificationApi from "@views/Notification/store/useNotificaitonApi";

import "./orderNotification.scss";
import { getCategoryLabel, limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import { assestType } from "@views/exchange/constants";

const OrderRequestNotification = (data: Json) => {
  const { updateMarkAsRead } = UseNotificationApi();
  const { formatCurrencyWithBillion, formatNumberWithBillion } = useCurrency();

  const notificationData = useMemo(() => {
    const issuerNameString = data?.data?.metadata?.assetType === assestType.crypto ? "" : data?.data?.metadata?.issuerName;
    return {
      title: limitFirstPart(
        mergeStringWithSymbol(issuerNameString, data?.data?.metadata?.symbol, "."), 11, ".") || "-",
      subTitle: mergeStringWithSymbol(issuerNameString, data?.data?.metadata?.name, ".") || "-",
      time: data?.data?.createdAt
        ? `${new Date(data?.data?.createdAt).getDate()} ${new Date(
          data?.data?.createdAt
        ).toLocaleDateString("en-US", {
          month: "short",
        })}, ${new Date(data?.data?.createdAt).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`
        : "-",
      userName: data?.data?.metadata?.orderPlacedBy,
      readStatus: data?.data?.readStatus,
      qty: data?.data?.metadata?.quantity,
      type: data?.data?.metadata?.type,
      avgPrice: data?.data?.metadata?.avgPrice,
      notificationId: data?.data?._id,
      assetType: getCategoryLabel(data?.data?.metadata?.assetType),
      sbaType: data?.data?.metadata?.sbaType?.replace(/-/g, ' ') || "",
      orderType: data?.data?.metadata?.orderType,
      userAvatar: data?.data?.metadata?.profileUrl,
      amount: data?.data?.metadata?.totalAmount,
      status: data?.data?.metadata?.status,
      approvalText: data?.data?.metadata?.approvalText,
      maxSupply: data?.data?.metadata?.maxSupply,
    };
  }, [data?.data?._id, data?.data?.createdAt, data?.data?.metadata?.assetName, data?.data?.metadata?.assetType, data?.data?.metadata?.avgPrice, data?.data?.metadata?.orderPlacedBy, data?.data?.metadata?.orderType, data?.data?.metadata?.profileUrl, data?.data?.metadata?.quantity, data?.data?.metadata?.totalAmount, data?.data?.readStatus]);

  const handleNotificationClick = useCallback((id: string) => {
    if (notificationData?.readStatus === "UNREAD") {
      updateMarkAsRead({ id: id });
    }
  }, [notificationData?.readStatus, updateMarkAsRead]);

  return (
    <div className="notificationSection" onClick={() => handleNotificationClick(notificationData?.notificationId)}>
      <div className="orderNotification mb-15">
        <div className="orderNotification__left">
          <div className="orderNotification__left__icon">
            <i className="ri-coins-fill orderCreation" />
          </div>
          {notificationData?.readStatus === "UNREAD" && (
            <div className="buysellNotification__left__readMark"></div>
          )}
          <div>
            <div className="orderNotification__left__title">
              {notificationData?.title}
            </div>
            <div className="orderNotification__left__Subtitle">
              {notificationData?.subTitle}
            </div>
          </div>
        </div>
        <div className="orderNotification__right">
          <div className="orderNotification__right__amount">
            {formatCurrencyWithBillion(notificationData?.amount, 2)}
          </div>
          <div className="orderNotification__right__orderDetails">
            <span className="orderNotification__right__orderDetails__price">
              <div>Price per share (USD).</div>
              <span>
                {formatCurrencyWithBillion(notificationData?.avgPrice, 2)}
              </span>
            </span>
          </div>
        </div>
      </div>
      {!!notificationData?.approvalText &&
        <div className="orderNotification-description">{notificationData?.approvalText}</div>}
      <div className="orderNotification mb-15">
        <div className="orderNotification__left with-no-icon">
          <div className="orderNotification__right__orderDetails__price">
            <div>Number of shares.</div>
            <span>
                {formatNumberWithBillion(notificationData?.maxSupply, 2)}
              </span>
          </div>
        </div>
        <div className="orderNotification__right">
          <div className="orderNotification__right__orderDetails__orderType mb-0 mt-0">
            {notificationData?.type && <div>{notificationData?.type}</div>}
            {!!notificationData?.sbaType && <div>{notificationData?.sbaType ?? "--"}</div>}
            <div>{notificationData?.assetType ?? "--"}</div>
            <span
              className={`${
                notificationData?.orderType === "BUY"
                  ? "orderNotification__right__orderDetails__orderType__BUY"
                  : "orderNotification__right__orderDetails__orderType__SELL"
              }`}
            >
              {capitalizeFirstLetterOnly(notificationData?.orderType)}
            </span>
          </div>
        </div>
      </div>
      <div className="orderNotification__right__time">
        {notificationData?.time}
        {["APPROVED", "REJECTED"].includes(notificationData?.status) &&
          <span className={`request-status ${notificationData?.status === "REJECTED" ? "red" : ""}`}>
          {notificationData?.status?.toLowerCase()}
        </span>}
      </div>
    </div>
  );
};

export default OrderRequestNotification;
